import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image"
import { graphql } from 'gatsby'
import { Row, Col } from "react-bootstrap"
import Video from "../components/video"

export default ({ data, pageContext }) => {
    const { pieceInfo } = pageContext
    const images = data.images.nodes
    return (
        <Layout>
            <SEO title={`Elnaz Nourizadeh Performance ${pieceInfo.title} ${pieceInfo.material}`} />
            <Row className="pl-5 pt-5 pr-5 d-flex">
                <Col xs={12}>
                    <h1 style={{ "textAlign": "center" }}>{pieceInfo.title}</h1>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="px-5 py-4">
                    <Video
                        videoSrcURL="https://www.youtube.com/embed/y3BRDFntpZ8"
                        videoTitle="title"
                    />
                    {/* <Img fluid={data.mainImage.childImageSharp.fluid} /> */}
                </Col>
                <Col md={6} className="px-5 py-4">
                    <p  >
                        {pieceInfo.desc}
                    </p>
                    <p>
                        <b>Date : </b>{pieceInfo.date}
                    </p>
                    <p>
                        <b>Size : </b>{pieceInfo.size}
                    </p>
                    <p>
                        <b>Material : </b>{pieceInfo.material}
                    </p>
                </Col>
            </Row>
            <Row>
                {images.map(i =>
                    <Col md={6} className="px-5 py-4">
                        <Img fluid={i.childImageSharp.fluid} />
                    </Col>
                )
                }
            </Row>
        </Layout>
    )
}

export const query = graphql`
    query($directory: String!) {
        images: allFile(sort: {fields: name}, filter: {lowerCaseBase: {ne: "main.jpg"}, pieceDirectory: {eq: $directory}, pieceType: {eq : "performance"}}) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
            relativeDirectory
            base
            lowerCaseBase
          }
        }
        mainImage: file(lowerCaseBase: {eq: "main.jpg"}, pieceDirectory: {eq: $directory}, pieceType: {eq : "performance"}) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`